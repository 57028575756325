import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Test Week`}</strong></p>
    <p>{`:20 to establish a max:`}</p>
    <p>{`Shoulder Press and Weighted Strict Pullup`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`“Fran”`}</em></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Thrusters (95/65)`}</p>
    <p>{`Pullups`}</p>
    <p>{`then,`}</p>
    <p>{`Max Unbroken Double Unders `}<em parentName="p">{`(2 attempts)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      